import React from "react";
import axios from "axios";
import * as s from "./PDFDownloadBlock.module.css";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const config = {
  baseURL: "https://webhook.site/427d7f1c-5c86-498c-b25a-4f5f31afd6a2",
  headers: { "Content-Type": "application/json" },
  method: "post",
  timeout: 3000,
};
const instance = axios.create(config);

const PDFDownloadBlock = ({ block, post }) => {

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [acceptChecked, setAcceptChecked] = React.useState(false);
  const [errors, setErrors] = React.useState({
    firstName: null,
    lastName: null,
    email: null,
  });
  const desktopImage = {
    data: block.desktopImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.desktopImage?.alt || ``,
  };
  const mobileImage = {
    data: block.mobileImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.mobileImage?.alt || ``,
  };
  const [messageSent, setMessageSent] = React.useState(false);

  const handleAcceptCheckboxChange = (e) => {
    setAcceptChecked(e.target.checked);
  }

  const submitForm = (e) => {
    if (isValidForm()) {
      setMessageSent(true);

      const queryParams = JSON.parse(sessionStorage.getItem("queryParams"));
      const lines = [];
      try {
        for (var x in queryParams) {
          lines.push(`*${x}:* ${queryParams[x]}`)
        }
      } catch (e) { }

      let payloadForm = {
        event: 'pdfDownload',
        source: "orders.co",
        firstName: firstName,
        utm_source: queryParams?.utm_source || "",
        lastName: lastName,
        email: email,
        post: post,
      };

      const payload = {
        ...payloadForm, details: lines.join("\n")
      }

      instance
        .request({ data: JSON.stringify(payload) })
        .then(function (response) {
          // handle success
          console.log("PDF Download Form Was Submitted Successfully");
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          window.dataLayer.push({ ...payloadForm });
          setFirstName("");
          setLastName("");
          setEmail("");
          setErrors({
            firstName: null,
            lastName: null,
            email: null,
          });
          setMessageSent(true);
          // always executed
        });

      // makeZapierRequest(payload);

      const element = document.createElement("a");
      element.href = block?.file?.localFile?.publicURL;
      element.download = block?.file?.localFile?.base;
      element.click();
    }
  }

  const isValidForm = () => {
    let isValid = true;
    let newErrors = {
      firstName: null,
      lastName: null,
      email: null,
    };

    if (!firstName?.length) {
      isValid = false;
      newErrors.firstName = 'Please enter Your First Name';
    }

    if (!lastName?.length) {
      isValid = false;
      newErrors.lastName = 'Please enter Your Last Name';
    }

    if (!isEmailValid(email)) {
      isValid = false;
      newErrors.email = 'Please enter a valid Email';
    }

    setErrors(newErrors);
    return isValid;
  }

  const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  if (block?.show) {
    return (
      <div className={`${s.pdfDownloadBlock}`}>
        <div className='container px-0'>
          <div className='row mx-0'>
            <div className='col-lg-6 ps-lg-0'>
              <div className="imageWrapper position-relative">
                {!!desktopImage?.data ? (
                  <GatsbyImage
                    placeholder="none"
                    loading="eager"
                    image={desktopImage.data}
                    alt={desktopImage.alt}
                    className={`${s.desktopImage} d-none d-lg-block position-absolute`}
                  />
                ) : <StaticImage
                  quality={100}
                  placeholder="none"
                  loading="eager"
                  className={`${s.desktopImage} d-none d-lg-block position-absolute`}
                  alt="form image"
                  src="./desktop.png" />}

                {!!mobileImage?.data ? (
                  <GatsbyImage
                    placeholder="none"
                    loading="eager"
                    image={mobileImage.data}
                    alt={mobileImage.alt}
                    className={`d-lg-none ${s.mobileImage}`}
                  />
                ) : <StaticImage
                  quality={100}
                  placeholder="none"
                  loading="eager"
                  className={`d-lg-none ${s.mobileImage}`}
                  alt="form image"
                  src="./mobile.png" />}

              </div>
            </div>
            <div className='col-lg-6 pe-lg-0 mt-4 mt-lg-0'>
              <div className="row">
                <div className="col-lg-12">
                  <h3 className={s.title}>Receive Actionable Tips Immediately!</h3>
                </div>
                <div className="col-lg-12">
                  <div className={`${s.labelInputGroup}`}>
                    <div className={s.inputWrapper}>
                      <label>First Name</label>
                      <input
                        className={`${null != errors.firstName && s.invalid}`}
                        type="text"
                        name="firstname"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                      />
                      {/* {null != errors.firstName && <small style={{ color: 'red' }}>{errors.firstName}</small>} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className={`${s.labelInputGroup}`}>
                    <div className={s.inputWrapper}>
                      <label>Last Name</label>
                      <input
                        className={`${null != errors.lastName && s.invalid}`}
                        type="text"
                        name="lastname"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                      />
                      {/* {null != errors.lastName && <small style={{ color: 'red' }}>{errors.lastName}</small>} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className={`${s.labelInputGroup} w-100`}>
                    <div className={s.inputWrapper}>
                      <label>Email</label>
                      <input
                        className={`${null != errors.email && s.invalid}`}
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                      {/* {null != errors.email && <small style={{ color: 'red' }}>{errors.email}</small>} */}
                    </div>
                  </div>
                </div>



                <div className="col-12">
                  <label className={`${s.labelCheckboxGroup} w-100`}>
                    <input type="checkbox" onChange={handleAcceptCheckboxChange} />
                    I have read and accept the &nbsp;<a href="/privacy-policy" target="_blank"> Privacy Policy</a>.
                  </label>
                </div>
                <div className="col-12">
                  <button type="submit" disabled={!acceptChecked} className={`${s.sendButton}`} onClick={submitForm}>
                    Download PDF
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    );
  }
  return <></>
};

export default PDFDownloadBlock;
